import React from "react";
import './css/Header.css';

export const Header = () => {

    return (
        <header className={'header'}>
            <div className="shade"></div>
            <h1>MovieManiac</h1>
            <div className="user"></div>
        </header>)
}